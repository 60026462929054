import React  from 'react';
import { FaUser, FaFlag, FaComment, FaEnvelope } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import Footer from './hooks/Footer';
import Navbar from './Navbar';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";



const Contact = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const isValid = await trigger();
    if (!isValid) {
      // Prevent form submission if there are errors
      return;
    }
    // Handle form submission with valid data
    console.log('Form data:', data);
  };

  return (
    <div>

       
     
       
        
     
        <div>
            <div className="box1 overflow-hidden">
            <section class="Contact">
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
               <div className='relative'>
               <Navbar/>
               </div>
                </div>
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit 
                relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                    <motion.div className="flex xl:pt-5 pt-10"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                       <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                       <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0099e5]"></i></p></Link>
                    </motion.div>

                    <motion.div className="h-fit"
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}
                     variants={{
                         hidden: { opacity: 0, x: -50 },
                         visible: { opacity: 1, x: 0 },
                     }}>
                        <p className=" font-bold xl:text-[4rem] text-[2rem]">
                           Contact us
                        </p>
                    </motion.div>
                </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
          <div className="bg-[#000212] shadow-md pb-10 ">
            <div className="bg-[reen] mx-auto max-w-7xl overflow-hidden rounded-md petFom__inputs">
              <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-[1%] xl:pt-[3%] pt-[2%]">
                <div className="bg-[reen] h-fit">
                  <form
                    action="https://formsubmit.co/f6849ca50d44ce9feda732e3c95c7b2d"
                    onSubmit={onSubmit}
                    method="POST"
                    className="petFom__inputs"
                  >
                    <div className="flex justify-center pt-10 gap-3">
                      {/* <p className="font-bold text-[2rem] text-center pt-2 text-[black]">Contact Us</p> */}
                      <div>
                        {/* <img src="phonex.png" alt="" className="w-[64px]" /> */}
                      </div>
                    </div>

                    <div className="mx-auto max-w-2xl p-6">
                      <div className="grid gap-4">
                        <div className="relative">
                          <input
                            type="text"
                            placeholder="Name"
                            className="pl-10 pr-4 py-2 w-full border bg-[#111639] text-white border-[#0097D7] rounded-lg focus:outline-none focus:border-[#0097D7]"
                            {...register('Name', {
                              required: 'Your Name Is Required.',
                              maxLength: {
                                value: 100,
                                message: 'Name should not exceed 100 characters.',
                              },
                            })}
                          required/>
                          <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" color="#0097D7" />
                        </div>
                        {errors.Name && (
                          <div className="flex justify-center mt-2">
                            <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white mb-2">
                              {errors.Name.message}
                            </p>
                          </div>
                        )}

                        <div className="relative">
                          <input
                            type="email"
                            placeholder="Email"
                            className="pl-10 pr-4 py-2 w-full border bg-[#111639] border-[#0097D7] rounded-lg focus:outline-none focus:border-[#0097D7]"
                            {...register('email', {
                              required: 'Your Email Is Required.',
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address.',
                              },
                            })}
                          required/>
                          <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" color="#0097D7" />
                        </div>
                        {errors.email && (
                          <div className="flex justify-center mt-2">
                            <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white">
                              {errors.email.message}
                            </p>
                          </div>
                        )}

                        <div className="relative">
                          <input
                            type="text"
                            placeholder="Country"
                            className="pl-10 pr-4 py-2 w-full bg-[#111639] border border-[#0097D7] rounded-lg focus:outline-none focus:[#0097D7]"
                            {...register('Country', {
                              required: 'Please Enter Country.',
                              maxLength: {
                                value: 100,
                                message: 'Country should not exceed 100 characters.',
                              },
                            })}
                          required/>
                          <FaFlag className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" color='#0097D7' />
                        </div>
                        {errors.Country && (
                          <div className='flex  justify-center mt-2'>
                            <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
                              {errors.Country.message}
                            </p>
                          </div>
                        )}

<select
  id="my-select"
  {...register("selection", {
    required: 'Please select a service', // Updated error message
  })}
  className='border border-[#0097D7]  bg-[#111639] text-white rounded pl-2 pr-5 py-2 w-full focus:outline-none focus:ring-2  focus:border-[#0097D7] mb-5'
required>
  <option value="" className='text-[gray]'>
    Select Service
  </option>
  <option value="Restore Lost Crypto">Restore Lost Crypto</option>
  <option value="Romance Scam Recovery">Romance Scam Recovery</option>
  <option value="Bitcoin Recovery">Bitcoin Recovery</option>
  <option value="Wallet Tracking">Wallet Tracking</option>
  <option value="Spy">Spying</option>
  <option value="Social Media Hack">Social Media hack/Recovery</option>
  <option value="Icloud Unlock">Icloud Unlock</option>
  <option value="Account Activation">Account Activation</option>
  <option value="BlackMail/Extortion">BlackMail/Extortion</option>
  <option value="USDT Recovery/Hack">USDT Recovery/Hack</option>
  <option value="Whatsapp Hack/Spy">Whatsapp Hack/Spy</option>
  <option value="Gmail Recovery/Hack">Gmail Recovery/Hack</option>
  <option value="Password Recovery">Password Recovery</option>
  <option value="Background Check Removal">Background Check Removal</option>
  <option value="Tracking">Tracking</option>
  <option value="Phoen Unlock">Phone Unlock</option>
  <option value="Narcissist Victims Support Health Line">Narcissist Victims Support Health Line</option>
</select>
{errors.selection && ( // Updated error reference
  <div className='flex justify-center mt-2'>
    <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 mb-2 text-white ">
      {errors.selection.message} {/* Updated error message */}
    </p>
  </div>
)}


                        <div className="relative">
                          <textarea
                            placeholder="Message"
                            className="pl-10 pr-4 py-2 h-40 resize-none w-full bg-[#111639] text-white border border-[#0097D7] rounded-lg focus:outline-none focus:border-[#0097D7]"
                            rows={4}
                            {...register('Message', {
                              required: 'Message is Required',
                              maxLength: {
                                value: 2000,
                                message: 'Message should not exceed 2000 characters.',
                              },
                            })}
                          required/>
                          <FaComment className="absolute left-3 top-5 transform -translate-y-1/2 text-gray-500" color='#0097D7' />
                        </div>
                        {errors.Message && (
                          <div className='flex  justify-center mt-2'>
                            <p className="text-red mt-1 bg-[red] rounded-md pl-3 pr-3 text-white mb-3 ">
                              {errors.Message.message}
                            </p>
                          </div>
                        )}

                      </div>

                     
                      <div className='mt-3'>
                        <button className='bg-[#0097D7] hover:bg-[#0097D7] text-white px-4 py-2 rounded cursor-pointer' type='submit'>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="bg-[reen] h-fit">
                  <div className='flex justify-center'>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53089383.18714171!2d-138.06823843517054!3d35.69377179964879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sng!4v1693352094557!5m2!1sen!2sng"
                      width="600"
                      height="500"
                      title="Google Maps"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <Footer />
        </div>
     
    </div>
  );
};

export default Contact;
