import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import Footer from "./hooks/Footer";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import useMediaQuery from '@mui/material/useMediaQuery';
import Circle from "./hooks/Circle";
import Circlex from "./hooks/Circlex";
import Circlexx from "./hooks/Circlexx";
import Typography from '@mui/material/Typography';


const About = () => {
    const elementRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                elementRef.current.classList.add('shake-5s');
              } else {
                elementRef.current.classList.remove('shake-5s');
              }
            });
          },
          { threshold: 0.5 } // Adjust the threshold as needed
        );
    
        observer.observe(elementRef.current);
    
        // Cleanup the observer when the component unmounts
        return () => {
          observer.disconnect();
        };
      }, []);

      function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </Typography>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }
      
      const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
      };
      
      const fabWhiteStyle = {
          color: 'common.white',
          bgcolor: 'primary',
          '&:hover': {
            color: '#29d090', // Change the color on hover
            bgcolor: '#007FFF',
          },
        };
      
     
        const [value, setValue] = React.useState(0);
        const isMobile = useMediaQuery('(max-width: 600px)');
      
        const handleChange = (event, newValue) => {
          setValue(newValue);
        };
      
        const transitionDuration = 500;
      
        const fabs = [
          {
            color: 'primary',
            sx: fabStyle,
            icon: <StarIcon />, // Icon for Excellence
            label: 'Excellence',
          },
          {
            color: 'primary',
            sx: fabStyle,
            icon: <SecurityIcon />, // Icon for Integrity
            label: 'Integrity',
          },
          {
            color: 'primary',
            sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
            icon: <GroupIcon />, // Icon for Teamwork
            label: 'Teamwork',
          },
        ];


  return (
   <>

    <div className="box1 overflow-hidden">
        <section class="About">
            <div className='relative'>
                <Navbar/>
            </div>
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[2.5rem] text-[2rem]">
                    About <br /> Income Revoke Agency
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>



        <div className='bg-[#0F1418] h-fit'>
            <div className='mx-auto overflow-hidden md:max-w-7xl pt-5 '>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 shadow__box px-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[1%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-white'>At  Income Revoke Agency, we stand firmly by your side, poised to thwart the maneuvers of fraudsters and resolutely restore the fruits of your hard-earned labor. Through the fusion of our specialized expertise and cutting-edge technological prowess, we offer you an unparalleled mosaic of tranquility by reclaiming financial assets ensnared within the clutches of fraudulent schemes. Our team of seasoned professionals comprehends the profound repercussions of falling prey to scams, thus addressing each case with the finesse of a master craftsman. Meticulous in our discretion, we tailor bespoke solutions finely tuned to the unique cadence of your circumstances. Whether ensnared in the maze of online phishing, victimized by investment deceit, or ensnared by the allure of romance turned treacherous, Income Revoke Agency stand ready to unravel even the most intricate of predicaments.</p>
                            </div> <br />

                            <div>
                                <p className='text-white'>With a history of triumphant retrievals and an expansive network of esteemed clients worldwide, we have cemented our reputation as an impregnable fortress of recovery. As we diligently toil to restore your assets, we simultaneously dispatch an unambiguous message to those who perpetrate deception: their schemes will meet swift and exacting retribution. Bestow your unwavering confidence in Income Revoke Agency to redefine the landscape of romance scam recovery and illuminate the pathway to financial renewal.</p>
                            </div>
                        </div>
                        <div className='bg-reen-600 h-fit'>
                            <div className='flex justify-center items-center xl:pt-2 pt-0'>
                                <img src="https://www.dehaagsehogeschool.nl/sites/hhs/files/2022-11/Pro_Master_Cyber_SE_Shutterstock_2003176019.jpeg" alt="" 
                                className='rounded-md'/>
                            </div>
                        </div>
                   
                </div>



                
            </div>

            <div className='mx-auto overflow-hidden md:max-w-7xl'>
            <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className='bg-[reen] h-fit'>
                    <div>
                        <img src="https://thethaiger.com/wp-content/uploads/2023/07/hacker-2.jpg" 
                        alt="" className="rounded-md"/>
                    </div>
                </div>
                <div className='bg-[reen] h-fit'>
                <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
      <CssBaseline />
      <Box
        sx={{
            bgcolor: '#181a29', // Change the background color here
            width: '100%',
            position: 'relative',
            minHeight: 200,
            marginLeft: 0,
            marginRight: 0,
        }}
      >
        <AppBar position="static" sx={{ backgroundColor: "#111639" }}>
          <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="#0097D7" // Change the indicator color here
          textColor="#29d090"
          variant="fullWidth"
          aria-label="action tabs example"
          sx={{ "& .MuiTabs-indicator": { backgroundColor: "#0097D7" } }}
          >
            <Tab label="Scenarios" {...a11yProps(0)} />
            <Tab label="Record" {...a11yProps(1)} />
            <Tab label="Support" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <p className='font-bold text-[#C0C2CD]'>Expertise in Diverse Scenarios</p>
          </div>
          <div className='pt-3 text-[#C0C2CD]'>
          Income Revoke Agency boasts a team of seasoned professionals well-versed in a wide spectrum of scams. Whether it's cryptocurrency theft, investment fraud, or romance scams, our experts have successfully navigated countless scenarios. Clients can trust us to understand their unique situation and craft tailored solutions.
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <p className='font-bold text-[#C0C2CD]'>Proven Track Record</p>
          </div>
          <div className='pt-3 text-[#C0C2CD]'>
          Our extensive history of triumphs stands as a testament to our efficacy. We've successfully recovered funds for numerous clients, bringing scammers to justice and restoring financial security. With a track record of excellence, clients can be confident in our ability to deliver results.
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <p className='font-bold text-[#C0C2CD]'>Comprehensive Support</p>
          </div>
          <div className='pt-3 text-[#C0C2CD]'>
          Income Revoke Agency doesn't just stop at financial recovery. We provide comprehensive assistance, including digital security enhancements, evidence gathering, and legal support. Clients receive holistic support throughout their recovery journey, ensuring peace of mind and a smoother path to justice.
          </div>
        </TabPanel>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </Box>
                </Container>
                <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Success</p>
                                </div>
                            </div>
                        </div>
                </div>

            </div>
            </div>



                <div className='flex justify-center items-center pt-[5%] '>
                    <p className='font-bold text-white shake-5s'>Mission & Vision <i class="ri-sparkling-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Visison And Mission </p>
                </div>


              <div className='mx-auto md:max-w-7xl overflow-hidden'>
              <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[2%]'>
                    <div className='bg-gren-600 h-fit shadow__box'>
                        <div className='pl-5 pt-5'>
                            <img src="mission.png" alt="" className='w-[64px]'/>
                        </div>
                        <div className='pl-5 pt-5'>
                            <p className='text-white font-bold text-[20px]'>Our Mission</p>
                        </div>
                        <div className='pl-5 pt-1 pb-10'>
                            <p className='text-white'>
                            To establish a world where trust and digital safety prevail. We envision a future where individuals can navigate
                             the digital realm with confidence, knowing that they have a steadfast ally in Income Revoke Agency. We aspire to set new 
                             standards for online security, financial restitution, and victim support, reshaping the landscape of digital trust and protection.
                            </p>
                        </div>
                    </div>
                    <div className='bg-gren-600 h-fit shadow__box'>
                        <div className='pl-5 pt-5'>
                            <img src="opportunity.png" alt="" className='w-[64px]'/>
                        </div>
                        <div className='pl-5 pt-5'>
                            <p className='text-white font-bold text-[20px]'>Our Vision</p>
                        </div>
                        <div className='pl-5 pt-1 pb-5'>
                            <p className='text-white'>
                            To relentlessly champion the rights of victims and combat the ever-evolving landscape of financial fraud, 
                            online scams, and digital threats. We empower individuals, reclaim stolen assets, restore digital security, 
                            and provide unwavering support to those in need. Our mission is to bring financial justice and peace of mind 
                            to those affected by deception, with Income Revoke Agency leading the charge.
                            </p>
                        </div>
                    </div>
                </div>
              </div>

          
            
        </div>

                    <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>



        <Footer/>
    </div>
   
   
   
   </>
  )
}

export default About;