import React, {useEffect, useRef} from 'react';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import Footer from "./hooks/Footer";
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circle from "./hooks/Circle";
import Circlex from "./hooks/Circlex";
import Circlexx from "./hooks/Circlexx";
import { Link } from "react-router-dom";


const Crypto = () => {
    const elementRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                elementRef.current.classList.add('shake-5s');
              } else {
                elementRef.current.classList.remove('shake-5s');
              }
            });
          },
          { threshold: 0.5 } // Adjust the threshold as needed
        );
    
        observer.observe(elementRef.current);
    
        // Cleanup the observer when the component unmounts
        return () => {
          observer.disconnect();
        };
      }, []);

      function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </Typography>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `action-tab-${index}`,
          'aria-controls': `action-tabpanel-${index}`,
        };
      }
      
      const fabStyle = {
        position: 'absolute',
        bottom: 16,
        right: 16,
      };
      
      const fabWhiteStyle = {
          color: 'common.white',
          bgcolor: 'primary',
          '&:hover': {
            color: '#29d090', // Change the color on hover
            bgcolor: '#007FFF',
          },
        };
      
     
        const [value, setValue] = React.useState(0);
        const isMobile = useMediaQuery('(max-width: 600px)');
      
        const handleChange = (event, newValue) => {
          setValue(newValue);
        };
      
        const transitionDuration = 500;
      
        const fabs = [
          {
            color: 'primary',
            sx: fabStyle,
            icon: <StarIcon />, // Icon for Excellence
            label: 'Excellence',
          },
          {
            color: 'primary',
            sx: fabStyle,
            icon: <SecurityIcon />, // Icon for Integrity
            label: 'Integrity',
          },
          {
            color: 'primary',
            sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
            icon: <GroupIcon />, // Icon for Teamwork
            label: 'Teamwork',
          },
        ];
  return (
   <>

    <div className="box1 overflow-hidden">
        <section class="Bitcoin">
            <div className='relative'>
                <Navbar/>
            </div>
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[2.5rem] text-[2rem]">
                      Crypto Currency Recovery
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>



        <div className='bg-[#0F1418] h-fit'>
            <div className='mx-auto overflow-hidden md:max-w-7xl pt-5 '>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 shadow__box px-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[1%]'>
                        <div className='bg-reen-600 h-fit'>
                            <div>
                                <p className='text-white'>At Income Revoke Agency, we specialize in the retrieval of a wide spectrum of cryptocurrency assets that have fallen into the hands of scammers and fraudsters. Our Money Reclaim Asset Recovery Services represent a pioneering collaborative intelligence and asset tracing solution dedicated to assisting victims of cybercrimes and corporations in the recovery of their vanishing or pilfered assets. Our cadre of seasoned professionals, under the guidance of our founder, is equipped with state-of-the-art asset tracking and recovery technology to aid victims of cybercrimes, comprehend their unique situations, and furnish the requisite assistance for asset reclamation.</p>
                            </div> <br />

                            <div>
                                <p className='text-white'>We comprehend the profound ramifications of cybercrimes on both individuals and enterprises, and our steadfast commitment at Income Revoke Agency is to provide a dependable and efficacious service that alleviates the distress and hardship associated with asset loss. Our devoted team of experts labors diligently to ensure that every victim garners the indispensable backing for asset recovery. Leveraging our cutting-edge asset tracking and recovery technology, we can trace misplaced or filched assets, irrespective of the intricacy of the scenario. Our team stands well-prepared to confront any asset recovery challenge, and our dedication is to deliver a service that exceeds all anticipations.</p>
                            </div>
                        </div>
                        <div className='bg-reen-600 h-fit'>
                            <div className='flex justify-center items-center xl:pt-5 pt-0'>
                                <img src="https://millennialmagazine.com/wp-content/uploads/2022/04/bitcoin-trader.jpg" alt="" 
                                className='rounded-md'/>
                            </div>
                        </div>
                   
                </div>
            </div>



                <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'>Services <i class="ri-sparkling-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Our Key Services </p>
                </div>


                <div className="mx-auto overflow-hidden  md:max-w-7xl text-white">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="bitcoinx.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">All Cryptocurrencies <br/> Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="credit-card.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Fake Bank <br/>Investment Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.4, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="passive-income.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Fake Crypto<br/>Investment Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.5, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="loan.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Fake Broker site<br/>Investment Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="purse.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Hacked Crypto <br/> Wallet Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.7, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="shieldo.png" alt="" className="w-[45px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">24 Hrs<br/>Protect</p>
                        </div>
                    </motion.div>
                    {/* <div className="bg-green-600 h-20">

                    </div>
                    <div className="bg-green-600 h-20">

                    </div> */}
                </div>
            </div> 

            <div className='mx-auto overflow-hidden md:max-w-7xl pb-[10%]'>
                <div className='flex justify-center items-center pt-[5%]'>
                    <p className='font-bold text-white shake-5s'> Core Values <i class="ri-medal-2-line text-[#007FFF]"></i></p>
                </div>
                <div className='flex justify-center items-center' ref={elementRef}>
                     <p className='font-bold text-white text-[1.8rem] shake-5s'>Why Choose Us</p>
                </div>
                    <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-gree-600 h-fit'>
                            <div className='flex justify-center'>
                                <img src="https://laplageacademy.com/wp-content/uploads/2023/12/cyber-security-team-working-in-a-cyber-security-operations-center-soc-to-protect-systems-and-1-1024x683.jpg" alt="" />
                            </div>
                        </div>
                        <div className='bg-green600 h-fit'>
                        <div className='bg-[reen] h-fit'>
                            <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
                                <CssBaseline />
                                    <Box
                                        sx={{
                                            bgcolor: '#181a29', // Change the background color here
                                            width: '100%',
                                            position: 'relative',
                                            minHeight: 200,
                                            marginLeft: 0,
                                             marginRight: 0,
                                        }}
                                    >
                                    <AppBar position="static" sx={{ backgroundColor: "#111639" }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="#007FFF" // Change the indicator color here
                                            textColor="#29d090"
                                            variant="fullWidth"
                                            aria-label="action tabs example"
                                            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#0097D7" } }}
                                        >
                                            <Tab label="Excellence" {...a11yProps(0)} />
                                            <Tab label="Integrity" {...a11yProps(1)} />
                                            <Tab label="Teamwork" {...a11yProps(2)} />
                                      </Tabs>
                                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Continuous Improvement</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                             Through continuous refinement, we work diligently and tirelessly to surpass your expectations. Our unwavering commitment ensures that we won't rest until we've achieved excellence and completed the job to your utmost satisfaction.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div>
                             <p className='font-bold text-[#C0C2CD]'>Reliable Integrity</p>
                        </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                            Unwavering Ethical Commitment: We uphold the highest standards of trustworthiness and ethical conduct. Our reliable integrity is the cornerstone of our service, ensuring your complete confidence in our work. We are dedicated to maintaining your trust, always, because your peace of mind matters.
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div>
                            <p className='font-bold text-[#C0C2CD]'>Resilient Teamwork</p>
                         </div>
                        <div className='pt-3 text-[#C0C2CD]'>
                            Collaborative Determination: Our resilient teamwork thrives on collaboration and determination. We unite as a dedicated team, facing challenges head-on and delivering results together. With unwavering determination, we work seamlessly to achieve your goals, every step of the way.
                        </div>
                    </TabPanel>
                        {fabs.map((fab, index) => (
                            <Zoom
                                key={fab.color}
                                in={value === index}
                                timeout={transitionDuration}
                                style={{
                                transitionDelay: `${value === index ? transitionDuration : 0}ms`,
                            }}
                                unmountOnExit
                            >
                                <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
                                {fab.icon}
                                </Fab>
                            </Zoom>
                            ))}
                    </Box>
                </Container>
                        <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlex/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circlexx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circle/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold text-white'>Success</p>
                                </div>
                            </div>
                            </div>
                    </div>
                        </div>
                    </div>
                </div>

            
        </div>

                    <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>



        <Footer/>
    </div>
   
   
   
   </>
  )
}

export default Crypto;