import './App.css';
import {  Route,  Routes } from 'react-router-dom';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Crypto from './Components/Crypto';
import Romance from './Components/Romance';
import Social from "./Components/Social";
import Blackmail from "./Components/Blackmail";
import PhoneUnlock from "./Components/PhoneUnlock";
import AccountUnlock from "./Components/AccountUnlock";
import About from './Components/About';
import Contact from './Components/Contact';

function App() {
  return (
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/navbar' element={<Navbar/>}/>
        <Route path='/crypto' element={<Crypto/>}/>
        <Route path='/romance' element={<Romance/>}/>
        <Route path='/socialmedia' element={<Social/>}/>
        <Route path='/blackmail' element={<Blackmail/>}/>
        <Route path='/phoneUnlock' element={<PhoneUnlock/>}/>
        <Route path='/accountunlock' element={<AccountUnlock/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
  );
}

export default App;
